import { StaticImage } from "gatsby-plugin-image"
import React, { useContext } from "react"
import ContentDiv from "../components/contentDiv"
import Layout from "../components/layout"
import PageImage from "../components/pageImage"
import PageSection from "../components/pageSection"
import UnfoldBoxList, { ListItem } from "../components/unfoldBoxList"
import getDummyText from "../dummyText"
import Tiles from "../components/tiles"
import FirstSection from "../components/firstSection"
import styled from "styled-components"
import Text, { TextType } from "../components/text"
import Anforderungen from "../icons/anforderungen.svg"
import DasBietenWir from "../icons/das-bieten-wir.svg"
import DasErwartenWir from "../icons/das-erwarten-wir.svg"
import Aufgaben from "../icons/aufgaben.svg"
import { laptop } from "../util/mediaQueries"
import JobOffering from "../types/jobOffering"
import { BackendContext } from "../services"
import { Media } from "gatsby-plugin-fresnel"
import SEO from "../components/seo"

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-left: 20px;
  margin-top: 0;
  min-height: 100%;
  height: 100%;
`

const StyledLi = styled.li`
  font-size: 12px;
`

const iconCss = `
`

const OffersDiv = styled.div`
  @media ${laptop} {
    margin-right: 0;
    margin-left: 0;
  }
`

const AnforderungenIcon = styled(Anforderungen)`
  height: ${props => props.theme.subHeaderSize};
  width: ${props => props.theme.subHeaderSize};
  max-height: ${props => props.theme.subHeaderSize};
  min-width: ${props => props.theme.subHeaderSize};
  margin-right: 20px;
`
const DasBietenWirIcon = styled(DasBietenWir)`
  height: ${props => props.theme.subHeaderSize};
  width: ${props => props.theme.subHeaderSize};
  max-height: ${props => props.theme.subHeaderSize};
  min-width: ${props => props.theme.subHeaderSize};
  margin-right: 20px;
`
const DasErwartenWirIcon = styled(DasErwartenWir)`
  height: ${props => props.theme.subHeaderSize};
  width: ${props => props.theme.subHeaderSize};
  max-height: ${props => props.theme.subHeaderSize};
  min-width: ${props => props.theme.subHeaderSize};
  margin-right: 20px;
`
const AufgabenIcon = styled(Aufgaben)`
  height: ${props => props.theme.subHeaderSize};
  width: ${props => props.theme.subHeaderSize};
  max-height: ${props => props.theme.subHeaderSize};
  min-width: ${props => props.theme.subHeaderSize};
  margin-right: 20px;
`

const getBodyUl = (lines: string[]) => (
  <StyledUl>
    {lines.map((line, index) => (
      <StyledLi key={index}>
        <Text type={TextType.Body}>{line}</Text>
      </StyledLi>
    ))}
  </StyledUl>
)

const MwdText = () => <Text type={TextType.Body}>(m/w/d)</Text>

const getJobBox: (
  screenType: string
) => (desc: JobOffering) => ListItem = screenType => desc => {
  const tileList = [
    {
      title: "Aufgaben",
      icon: <AufgabenIcon />,
      body: getBodyUl(desc.aufgaben),
    },
    {
      title: "Anforderungen",
      icon: <AnforderungenIcon />,
      body: getBodyUl(desc.anforderungen),
    },
    {
      title: "Das erwarten wir",
      icon: <DasErwartenWirIcon />,
      body: getBodyUl(desc.dasErwartenWir),
    },
    {
      title: "Das bieten wir",
      icon: <DasBietenWirIcon />,
      body: getBodyUl(desc.dasBietenWir),
    },
  ]
  console.log(`SUUUUU ${desc.maxHeightLarge}`)
  const maxHeight = `${
    screenType === "lg" || screenType === "xl"
      ? desc.maxHeightLarge
      : screenType === "md"
      ? desc.maxHeightTablet
      : desc.maxHeightPhone
  }px`
  console.log(`Masxxxxx: ${maxHeight}`)

  return {
    title: desc.name,
    body: <Tiles tileList={tileList} />,
    icon: [<MwdText />, <MwdText />],
    maxHeight: maxHeight,
  }
}

const Karriere = () => {
  const { jobOfferings } = useContext(BackendContext)
  return (
    <Layout>
      <SEO lang="de" title="Karriere" />
      <PageImage>
        <Media greaterThanOrEqual="md">
          <StaticImage
            src="../images/Titelbild_Karriere_PC.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
        <Media at="sm">
          <StaticImage
            src="../images/Titelbild_Karriere_Smartp.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
      </PageImage>
      <FirstSection headerText="Unsere aktuellen Stellenangebote">
        Wir sind auf der Suche nach engagierten Mitarbeitern, die mit uns
        gemeinsam unsere Mandanten optimal betreuen und am Erfolg teilhaben
        wollen.
      </FirstSection>
      <PageSection>
        <ContentDiv>
          <OffersDiv>
            <Media greaterThanOrEqual="lg">
              <UnfoldBoxList items={jobOfferings.map(getJobBox("lg"))} />
            </Media>
            <Media at="md">
              <UnfoldBoxList items={jobOfferings.map(getJobBox("md"))} />
            </Media>
            <Media at="sm">
              <UnfoldBoxList items={jobOfferings.map(getJobBox("sm"))} />
            </Media>
          </OffersDiv>
        </ContentDiv>
      </PageSection>
    </Layout>
  )
}

export default Karriere
