import React from "react"
import styled from "styled-components"
import Text, { TextType } from "./text"
import { laptop, tablet } from "../util/mediaQueries"

interface TileContent {
    title: string
    icon: React.ReactNode
    body: React.ReactNode
}

const Row = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    align-items: stretch;
    @media ${laptop} {
      flex-direction: row;
    }
`


const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.main};
`

const TileDiv = styled.div`
    background: white;
    color: ${props => props.theme.textColor};
    @media ${laptop} {
      border-radius: 10px;
      margin: 20px;
      box-shadow: ${props => props.theme.shadow};
      padding: 20px;
    }
    width: 50%;
`


const Tile = (props: TileContent) => (
    <TileDiv>
        <HeaderRow>
            {props.icon}
            <Text type={TextType.SubSubHeader} bold >{props.title}</Text>
        </HeaderRow>
        {props.body}
    </TileDiv>
)

interface Props {
    tileList: TileContent[]
}


const createPairs = <T,>(list: T[]) => {
    let out: T[][] = []
    let pair: T[] = []
    list.forEach(x => {
        if (pair.length === 0) pair.push(x)
        else {
            pair.push(x)
            out.push(pair)
            pair = []
        }
    })
    return out
}

const RootDiv = styled.div`
        margin: 3px;
        border-radius: 0px 0px 10px 10px;
        overflow: hidden;
        background: white;
        padding: 20px;
`

const Tiles = (props: Props) => (
    <RootDiv>
        {createPairs(props.tileList).map(tilePair => (
            <Row>
                <Tile {...tilePair[0]} />
                {tilePair[1] && <Tile {...tilePair[1]} />}
            </Row>
        ))}
    </RootDiv>
)

export default Tiles
